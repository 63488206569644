import { Helmet } from 'react-helmet-async';
import { TITLES } from '../types/titles';
import PasswordRecoveryLayout from '../components/login/password-recovery';

const LoginRecovery = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.RECOVERY}</title>
			</Helmet>
			<PasswordRecoveryLayout />
		</>
	);
};

export default LoginRecovery;
