import { Helmet } from 'react-helmet-async';
import { TITLES } from '../types/titles';
import VerificationSumSubLayout from '../components/verification/sumsub';
import { QueryClient } from '@tanstack/react-query';
import { fetchSumSubInfo } from '../api/verification';

export const verificationSumSubLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['verificationSumSub'],
		queryFn: () => fetchSumSubInfo(),
		staleTime: Infinity
	});
};

const VerificationSumSub = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.VERIFICATION.SUMSUB}</title>
			</Helmet>
			<VerificationSumSubLayout />
		</>
	);
};

export default VerificationSumSub;
