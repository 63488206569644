import { useEffect, useState } from 'react';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { CustomButton } from '../common/buttons';
import { ROUTES } from '../../types/routes';

const DocumentLayout = () => {
	const navigate = useCustomNavigate();

	const [href, setHref] = useState<string>('');

	const [isDesktop, setIsDesktop] = useState<boolean>(false);

	useEffect(() => {
		if (window.innerWidth >= 481) {
			setIsDesktop(true);
		}
		setHref(sessionStorage.getItem('href') as string);
	}, []);

	const handleBackClickButton = () => {
		navigate(ROUTES.REGISTRATION.INDEX);
	};

	return (
		<div
			className={'component'}
			style={
				isDesktop
					? {
							bottom: 'auto !important',
							marginTop: '64px',
							height: '100%',
					  }
					: { height: '100%' }
			}
		>
			<div
				className={'innerComponent'}
				style={{
					width: 'calc(100% - 36px)',
					height: '100%',
				}}
			>
				<CustomButton
					isBack={true}
					onClick={handleBackClickButton}
					className={'backButton'}
				/>
				<iframe
					style={{
						border: '0',
						height: '100%',
						width: '100%',
						background: 'white'
					}}
					src={href}
				/>
			</div>
		</div>
	);
};

export default DocumentLayout;
