import { MutableRefObject } from 'react';
import { Button, CircularProgress, styled } from '@mui/material';
import { West } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TStyledButtonProps } from '../../../types/styled/types';
import { TTheme } from '../../../types/types';

interface IRegistrationButtonProps {
	imgSrc: string;
	text: string;
	onClick: () => void;
}
const RegistrationButton = ({ imgSrc, text, onClick }: IRegistrationButtonProps) => {
	return (
		<button className={'registrationButton'} onClick={onClick}>
			<div className="registrationButtonContent">
				<div className={'registrationButtonContentIconText'}>
					<img
						className="registrationButtonImageContainer"
						src={imgSrc}
						alt="icon"
					/>
					<h1 className="registrationButtonText">{text}</h1>
				</div>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M13.0603 11.8891L8.81758 7.64644C8.42705 7.25592 8.42705 6.62276 8.81757 6.23223C9.20809 5.8417 9.84126 5.8417 10.2318 6.23222L15.8887 11.8891L10.2318 17.546C9.84125 17.9365 9.20809 17.9365 8.81757 17.546C8.42705 17.1555 8.42705 16.5223 8.81757 16.1318L13.0603 11.8891Z"
						fill="#BFC1C5"
					/>
				</svg>
			</div>
		</button>
	);
};

const mapButtonSxByType = ({
	isExchangeBackButton,
	isClose,
	isSelectAsset,
	isText,
	isBack,
	isShowMore,
	isSwapButton,
	isGrey,
	isDarkMode,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TStyledButtonProps): any => {
	const defaultSx = {
		textTransform: 'unset !important',
		fontFamily: 'Noto Sans !important',
		fontWeight: '400 !important',
		fontSize: '16px !important',
		fontStyle: 'normal !important',
		lineHeight: '24px !important',
		height: '56px !important',
		borderRadius: '12px !important',
	};
	if (isExchangeBackButton) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background: '#EBECEE !important',
			},
			background:
				isDarkMode === 'dark' ? '#303030 !important' : '#EBECEE !important',
			color: isDarkMode === 'dark' ? '#FEFEFE !important' : '#181A21 !important',
		});
	}
	if (isClose) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background: '#F6F6F8 !important',
			},
			background:
				isDarkMode === 'dark' ? '#1F1F1F !important' : '#F6F6F8 !important',
			borderRadius: '50% !important',
			minWidth: '42px !important',
			width: '42px !important',
			height: '42px !important',
			padding: '1px 1px 1px 1px !important',
		});
	}
	if (isSwapButton) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background:
					isDarkMode === 'dark' ? '#303030 !important' : '#EBECEE !important',
			},
			'&:hover': {
				background: '#00BEB4',
			},
			'&:active': {
				background: '#00BEB4',
			},
			'&:focus': {
				background: '#00BEB4',
			},
			background: '#00BEB4',
			borderRadius: '50% !important',
			minWidth: '48px !important',
			width: '48px !important',
			height: '48px !important',
			padding: '1px 1px 1px 1px !important',
		});
	}
	if (isSelectAsset) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background: '#EBECEE !important',
			},
			background:
				isDarkMode === 'dark' ? '#1F1F1F !important' : '#F6F6F8 !important',
			color: isDarkMode === 'dark' ? '#FEFEFE !important' : '#848790 !important',
			fontSize: '14px !important',
			lineHeight: '20px !important',
			height: '36px !important',
			padding: '1px 3px 1px 3px !important',
		});
	}
	if (isShowMore) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background:
					isDarkMode === 'dark' ? '#9C9C9D !important' : '#EBECEE !important',
			},
			background:
				isDarkMode === 'dark' ? '#1F1F1F !important' : '#F6F6F8 !important',
			color: isDarkMode === 'dark' ? '#9C9C9D !important' : '#848790 !important',
			fontSize: '14px !important',
			lineHeight: '20px !important',
			height: '46px !important',
		});
	}
	if (isText) {
		return Object.assign(defaultSx, {
			fontWeight: '600 !important',
			height: '60px !important',
			textAlign: 'center',
			color: '#00BEB4',
		});
	}
	if (isBack) {
		return Object.assign(defaultSx, {
			textAlign: 'center',
			color: '#00BEB4',
			height: '40px !important',
			justifyContent: 'flex-start',
		});
	}
	if (isGrey) {
		return Object.assign(defaultSx, {
			'&.Mui-disabled': {
				background: '#EBECEE !important',
				color: '#181A21 !important',
			},
			'&:hover': {
				background: '#EBECEE',
			},
			'&:active': {
				background: '#EBECEE',
			},
			'&:focus': {
				background: '#EBECEE',
			},
			background: '#EBECEE',
			color: '#181A21 !important',
		});
	}
	return Object.assign(defaultSx, {
		'&.Mui-disabled': {
			background:
				isDarkMode === 'dark' ? '#303030 !important' : '#EBECEE !important',
			color: isDarkMode === 'dark' ? '#5F5F5F !important' : '#BFC1C5 !important',
		},
		'&:hover': {
			background: '#00BEB4',
		},
		'&:active': {
			background: '#00BEB4',
		},
		'&:focus': {
			background: '#00BEB4',
		},
		background: '#00BEB4',
		color: '#FFF !important',
	});
};

const StyledButton = styled(Button, {
	shouldForwardProp: (prop) =>
		prop !== 'isExchangeBackButton' &&
		prop !== 'isClose' &&
		prop !== 'isSelectAsset' &&
		prop !== 'isBack' &&
		prop !== 'isShowMore' &&
		prop !== 'isSwapButton' &&
		prop !== 'isGrey' &&
		prop !== 'isDarkMode',
})<TStyledButtonProps>(
	({
		isExchangeBackButton,
		isClose,
		isSelectAsset,
		isBack,
		isText,
		isShowMore,
		isSwapButton,
		isGrey,
		isDarkMode,
	}) =>
		mapButtonSxByType({
			isExchangeBackButton,
			isClose,
			isSelectAsset,
			isText,
			isBack,
			isShowMore,
			isSwapButton,
			isGrey,
			isDarkMode,
		})
);

interface Props {
	isLoading?: boolean;
	isDisabled?: boolean;
	text?: string;
	type?: 'button' | 'submit' | 'reset';
	onClick: () => void;
	className: string;
	isBack?: boolean;
	isGrey?: boolean;
	isText?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	buttonRef?: MutableRefObject<any>;
	isShowMore?: boolean;
	isExpanded?: boolean;
	isSelectAsset?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children?: any;
	isSwapButton?: boolean;
	isClose?: boolean;
	isExchangeBackButton?: boolean;
}
const CustomButton = ({
	isLoading,
	isDisabled,
	text,
	type,
	onClick,
	className,
	isBack,
	isText,
	buttonRef,
	isShowMore,
	isExpanded,
	isSelectAsset,
	children,
	isSwapButton,
	isClose,
	isExchangeBackButton,
	isGrey,
}: Props) => {
	const theme = localStorage.getItem('theme') as string;
	const { t } = useTranslation();

	if (isExchangeBackButton) {
		return (
			<div className={className}>
				<StyledButton
					isExchangeBackButton
					isDarkMode={theme as TTheme}
					fullWidth
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={'contained'}
					disabled={isDisabled}
					onClick={onClick}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isGrey) {
		return (
			<div className={className}>
				<StyledButton
					ref={buttonRef}
					isDarkMode={theme as TTheme}
					fullWidth
					isGrey
					endIcon={
						isLoading ? (
							<CircularProgress
								sx={{
									'.MuiCircularProgress-circle': {
										stroke: '#FFFFFF',
									},
								}}
								thickness={5}
								size={15}
							/>
						) : (
							''
						)
					}
					disableElevation
					type={type}
					variant={'contained'}
					disabled={isDisabled}
					onClick={onClick}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isClose) {
		return (
			<div className={className}>
				<StyledButton
					isClose
					isDarkMode={theme as TTheme}
					fullWidth
					TouchRippleProps={{
						classes: {
							child: 'analyticsShowMoreButtonLayout',
						},
					}}
					className={''}
					disabled={isDisabled}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={'contained'}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isSwapButton) {
		return (
			<div className={className}>
				<StyledButton
					isSwapButton
					isDarkMode={theme as TTheme}
					fullWidth
					TouchRippleProps={{
						classes: {
							child: 'analyticsShowMoreButtonLayout',
						},
					}}
					className={''}
					disabled={isDisabled}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={'contained'}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isSelectAsset) {
		return (
			<div className={className}>
				<StyledButton
					isSelectAsset
					isDarkMode={theme as TTheme}
					fullWidth
					TouchRippleProps={{
						classes: {
							child: 'analyticsShowMoreButtonLayout',
						},
					}}
					className={className}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={'contained'}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isShowMore) {
		return (
			<div className={className}>
				<StyledButton
					isShowMore
					isDarkMode={theme as TTheme}
					fullWidth
					TouchRippleProps={{
						classes: {
							child: 'analyticsShowMoreButtonLayout',
						},
					}}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={'contained'}
					onClick={onClick}
					endIcon={
						isExpanded ? (
							<KeyboardArrowDownIcon
								sx={{
									color: '#848790',
								}}
							/>
						) : (
							<KeyboardArrowUpIcon
								sx={{
									color: '#848790',
								}}
							/>
						)
					}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isText) {
		return (
			<div className={className}>
				<StyledButton
					isText
					isDarkMode={theme as TTheme}
					disableElevation
					variant={'text'}
					fullWidth
					onClick={onClick}
					endIcon={
						isLoading ? (
							<CircularProgress
								sx={{
									'.MuiCircularProgress-circle': {
										stroke: '#00beb4',
									},
								}}
								thickness={5}
								size={15}
							/>
						) : (
							''
						)
					}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isBack) {
		return (
			<div className={className}>
				<StyledButton
					isBack
					isDarkMode={theme as TTheme}
					disableElevation
					variant={'text'}
					fullWidth
					onClick={onClick}
					startIcon={<West />}
				>
					{t('returnText')}
				</StyledButton>
			</div>
		);
	}

	return (
		<div className={className}>
			<StyledButton
				ref={buttonRef}
				fullWidth
				isDarkMode={theme as TTheme}
				endIcon={
					isLoading ? (
						<CircularProgress
							sx={{
								'.MuiCircularProgress-circle': {
									stroke: '#FFFFFF',
								},
							}}
							thickness={5}
							size={15}
						/>
					) : (
						''
					)
				}
				disableElevation
				type={type}
				variant={'contained'}
				disabled={isDisabled}
				onClick={onClick}
			>
				{text}
			</StyledButton>
		</div>
	);
};

export { CustomButton, RegistrationButton };
