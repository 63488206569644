import { Checkbox, styled } from '@mui/material';
import { TStyledCheckboxProps } from '../../../types/styled/types';

const StyledCheckbox = styled(Checkbox, {
	shouldForwardProp: (prop) => prop !== 'isError' && prop !== 'isDarkMode',
})<TStyledCheckboxProps>(({ isError, isDarkMode }) => ({
	'&.Mui-checked': {
		color: '#00BEB4 !important',
	},
	'& .MuiIconButton-label': {
		position: 'relative',
		zIndex: 0,
	},
	'& .MuiSvgIcon-root': {
		fontSize: 28,
	},
	padding: '0px !important',
	'&:not(.Mui-checked) .PrivateSwitchBase-input': {
		width: '24px',
		height: '24px',
		top: 'auto',
		left: 'auto',
		opacity: '1',
		visibility: 'hidden',

		'&::before': {
			content: '""',
			position: 'absolute',
			background:
				isDarkMode === 'dark'
					? isError
						? '#332324 !important'
						: '#303030 !important'
					: isError
					? '#FDEDEB !important'
					: '#EBECEE !important',
			height: '100%',
			width: '100%',
			borderRadius: '4px !important',
			visibility: 'visible',
		},
	},
	color:
		isDarkMode === 'dark'
			? isError
				? '#332324 !important'
				: '#303030 !important'
			: isError
			? '#FDEDEB !important'
			: '#EBECEE !important',
	borderRadius: '50% !important',
}));

const CustomCheckbox = (props: TStyledCheckboxProps) => {
	return (
		<StyledCheckbox
			isDarkMode={props.isDarkMode}
			key={props.key}
			onChange={props.onChange}
			checked={props.checked}
			isError={props.isError}
		/>
	);
};

export { CustomCheckbox };
