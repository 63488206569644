import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import i18n from './locale/i18n';
import './styles/index.css';
import LocaleProvider from './data/locale.provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { router } from './router';
import ParamProvider from './data/param.provider';
import Header from './components/common/header';
import { TTheme } from './types/types';
import ThemeProvider from './data/theme.provider';

const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

const queryClient = new QueryClient();

const App = () => {
	useEffect(() => {
		i18n.changeLanguage(locale);
		sessionStorage.setItem(
			'wstate',
			new URLSearchParams(window.location.search).get('wstate') as string
		);
		const theme = localStorage.getItem('theme') as TTheme;
		if (theme === 'dark') {
			document.body.setAttribute('data-theme', theme);
		}
	}, []);

	return (
		<div id={'wrapper'} className={'wrapper'}>
			<QueryClientProvider client={queryClient}>
				<LocaleProvider>
					<ParamProvider>
						<ThemeProvider>
							<Header>
								<RouterProvider router={router} />
							</Header>
						</ThemeProvider>
					</ParamProvider>
				</LocaleProvider>
			</QueryClientProvider>
		</div>
	);
};

export default App;
