import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons';
import { PinInput } from '../../common/inputs';
import { useContext, useEffect, useState } from 'react';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ParamContext } from '../../../data/param.provider';
import { usePinInput } from 'react-pin-input-hook';
import {
	useSendCodePasswordRecovery,
	useVerifyCodePassword
} from '../../../data/mutations/login';

const ConfirmEmailLayout = () => {
	const { wState } = useContext(ParamContext);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [email, setEmail] = useState<string>('');

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				verifyCodePassword({
					wState: wState,
					email: email,
					code: values,
					navigate: navigate
				});
			}
		},
		type: 'numeric',
		placeholder: ''
	});

	const [counter, setCounter] = useState<number>(60);

	const navigate = useCustomNavigate();

	const { isError, error, verifyCodePassword, isPending } =
		useVerifyCodePassword(navigate);

	const { sendCode } = useSendCodePasswordRecovery(navigate);

	const { t } = useTranslation();

	const [isResendCodeActive, setIsResendCodeActive] =
		useState<boolean>(false);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	useEffect(() => {
		if (counter == 0) {
			setIsResendCodeActive(true);
			setCounter(60);
		}
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	useEffect(() => {
		const sessionEmail = sessionStorage.getItem('email');
		if (sessionEmail) {
			setEmail(sessionEmail);
		}
	}, []);

	const handleResendCode = () => {
		sendCode({
			wState: wState,
			email: email,
			navigate
		});
	};

	const handleBackClickButton = () => {
		navigate(ROUTES.LOGIN.PASSWORD_RECOVERY);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<CustomButton
					isBack={true}
					onClick={handleBackClickButton}
					className={'backButton'}
				/>
				<h1 className={'confirmEmailText'}>
					{t('authenticationText')}
				</h1>
				<h1 className={'confirmCodeText'}>
					{t('mfCodeEmailDescription').replace('$email$', email)}
				</h1>
				<div className={'codeInputBlock'}>
					<PinInput isLoading={isPending} isError={fakeError} fields={fields} />
					{fakeError ? (
						<h1 className={'errorText'}>
							{
								(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
							}
						</h1>
					) : (
						<div className={'errorText'} />
					)}
					{isResendCodeActive ? (
						<h1
							className={'resendCodeText'}
							onClick={handleResendCode}
						>
							{t('registrationResend')}
						</h1>
					) : (
						<h1 className={'resendCodeText-inactive'}>
							{t('registrationResendIn')} {counter}
						</h1>
					)}
				</div>
			</div>
		</div>
	);
};

export default ConfirmEmailLayout;
