import { useMutation } from '@tanstack/react-query';
import {
	TConfirmTwoFaRequestBody,
	TImpersonateBody,
	TLoginBody,
	TVerifyNewPasswordRequestBody
} from '../../types/types';
import {
	fetchConfirmTwoFa,
	fetchImpersonate,
	fetchLoginOTP,
	fetchLoginWithTwoFA,
	fetchLoginWithoutTwoFA,
	fetchRequestCodeForRecovery,
	fetchVerifyCodePassword
} from '../../api/login';
import { ROUTES } from '../../types/routes';
import { mapLoginPage } from '../../utils';

export function useLoginWithoutTwoFAMutation(navigate: (path: string) => void) {
	const {
		mutateAsync: login,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['loginWithoutTwoFA'],
		mutationFn: (data: TLoginBody) =>
			fetchLoginWithoutTwoFA(data, navigate),
		//eslint-disable-next-line
		onSuccess: (data: any) => {
			if (data.nextPageId === 'finishPageId') {
				window.location.replace(
					`/${window.location.pathname.split('/')[1]}/widget/finish`
				);
			} else {
				navigate(mapLoginPage(data.nextPageId) as string);
			}
		}
	});
	return {
		login,
		error,
		isError,
		isPending
	};
}

export function useLoginWithTwoFAMutation(navigate: (path: string) => void) {
	const {
		mutateAsync: login,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['loginWithTwoFA'],
		mutationFn: (data: TLoginBody) => fetchLoginWithTwoFA(data, navigate),
		//eslint-disable-next-line
		onSuccess: (data: any) => {
			if (data.nextPageId === 'finishPageId') {
				window.location.replace(
					`/${window.location.pathname.split('/')[1]}/widget/finish`
				);
			} else {
				navigate(mapLoginPage(data.nextPageId) as string);
			}
		}
	});
	return {
		login,
		error,
		isError,
		isPending
	};
}

export function useImpersonateMutation(navigate: (path: string) => void) {
	const {
		mutateAsync: impersonate,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['loginImpersonate'],
		mutationFn: (data: TImpersonateBody) =>
			fetchImpersonate(data, navigate),
		//eslint-disable-next-line
		onSuccess: (data: any) => {
			if (data.nextPageId === 'finishPageId') {
				window.location.replace(
					`/${window.location.pathname.split('/')[1]}/widget/finish`
				);
			}
		}
	});
	return {
		impersonate,
		error,
		isError,
		isPending
	};
}

export function useSendOtpCodeMutation(navigate: (path: string) => void) {
	const {
		mutateAsync: sendOtpCode,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['sendOtpCode'],
		mutationFn: (data: {
			wState: string;
			identityType: string;
			f2xUserAgent: string;
			navigate: (path: string) => void;
		}) =>
			fetchLoginOTP(
				data.wState,
				data.identityType,
				data.f2xUserAgent,
				navigate
			),
		onSuccess: async () => {
			navigate(ROUTES.LOGIN.OTP);
		}
	});
	return {
		sendOtpCode,
		error,
		isError,
		isPending
	};
}

export function useSendCodePasswordRecovery(navigate: (path: string) => void) {
	const {
		mutateAsync: sendCode,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['sendCodeRecovery'],
		mutationFn: (data: {
			wState: string;
			email: string;
			navigate: (path: string) => void;
		}) => fetchRequestCodeForRecovery(data),
		onSuccess: async () => {
			navigate(ROUTES.LOGIN.CONFIRM_EMAIL);
		}
	});
	return {
		sendCode,
		error,
		isError,
		isPending
	};
}

export function useAskNewCodePasswordRecovery() {
	const {
		mutateAsync: sendCode,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['askNewCodeRecovery'],
		mutationFn: (data: {
			wState: string;
			email: string;
			navigate: (path: string) => void;
		}) => fetchRequestCodeForRecovery(data)
	});
	return {
		sendCode,
		error,
		isError,
		isPending
	};
}

export function useVerifyCodePassword(navigate: (path: string) => void) {
	const {
		mutateAsync: verifyCodePassword,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['verifyPasswordRecovery'],
		mutationFn: (data: TVerifyNewPasswordRequestBody) =>
			fetchVerifyCodePassword(data),
		onSuccess: async () => {
			navigate(ROUTES.LOGIN.INDEX);
		}
	});
	return {
		verifyCodePassword,
		error,
		isError,
		isPending
	};
}

export function useConfirmTwoFaMutation(navigate: (path: string) => void) {
	const {
		mutateAsync,
		isError,
		error: error,
		isPending
	} = useMutation({
		mutationKey: ['confirmTwoFa'],
		mutationFn: (data: TConfirmTwoFaRequestBody) => fetchConfirmTwoFa(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if (data.nextPageId === 'finishPageId') {
				window.location.replace(
					`/${window.location.pathname.split('/')[1]}/widget/finish`
				);
			}
			navigate(mapLoginPage(data.nextPageId) as string);
			return;
		}
	});
	return {
		mutateAsync,
		error,
		isError,
		isPending
	};
}
