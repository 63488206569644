import { useMutation } from '@tanstack/react-query';
import {
	fetchAskNewEmailCode,
	fetchAskNewPhoneCode,
	fetchSubmitClear,
	fetchSubmitEmailCode,
	fetchSubmitFirstStep,
	fetchSubmitLastStep,
	fetchSubmitPhoneCode,
	fetchSubmitPhoneVerifyCode
} from '../../api/registration';
import { TMappedCheckboxes } from '../../types/types';
import { ROUTES } from '../../types/routes';
import { mapRegistrationPage } from '../../utils';

export function useSubmitClearMutation(navigate: (path: string) => void) {
	const { mutateAsync: mutateClearAsync, isPending: isClearLoading } = useMutation({
		mutationKey: ['submitClear'],
		mutationFn: fetchSubmitClear,
		onSuccess: async () => {
			localStorage.removeItem('state');
			navigate(ROUTES.LOGIN.INDEX);
			sessionStorage.clear();
		}
	});
	return {
		mutateClearAsync,
		isClearLoading
	};
}

export function useSubmitRegistrationAgainMutation(navigate: (path: string) => void) {
	const { mutateAsync: mutateClearAsync, isPending: isClearLoading } = useMutation({
		mutationKey: ['submitClear'],
		mutationFn: fetchSubmitClear,
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			if(data.nextPageId === 'registrationEulaEmailIdentityPageId') {
				localStorage.removeItem('state');
				navigate(ROUTES.REGISTRATION.INDEX);
				sessionStorage.clear();
			}
		}
	});
	return {
		mutateClearAsync,
		isClearLoading
	};
}

export function useSubmitFirstStepMutation(
	navigate: (path: string) => void,
	isEmailVerified: boolean
) {
	const {
		mutateAsync,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['submitFirstStep'],
		mutationFn: (data: {
			email: string;
			checkBoxes: TMappedCheckboxes;
			isEmailVerified: boolean;
			wState: string;
			navigate: (path: string) => void;
		}) => fetchSubmitFirstStep(data),
		onSuccess: async () => {
			if (isEmailVerified) {
				navigate(ROUTES.REGISTRATION.PHONE);
				return;
			}
			navigate(ROUTES.REGISTRATION.EMAIL_CODE);
		}
	});
	return {
		mutateAsync,
		isPending,
		mutationError,
		isError
	};
}

export function useAskNewEmailCodeMutation() {
	const {
		mutateAsync: askNewCode,
		isError,
		error: askNewCodeError
	} = useMutation({
		mutationKey: ['askNewEmailCode'],
		mutationFn: fetchAskNewEmailCode
	});
	return {
		askNewCode,
		askNewCodeError,
		isError
	};
}

export function useSubmitEmailCodeMutation(navigate: (path: string) => void) {
	const {
		isPending,
		mutateAsync,
		isError,
		error: mutationError
	} = useMutation({
		mutationKey: ['submitEmailCode'],
		mutationFn: (data: { code: string }) => fetchSubmitEmailCode(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			navigate(mapRegistrationPage(data.nextPageId) as string);
			return;
		}
	});
	return {
		mutateAsync,
		mutationError,
		isError,
		isPending
	};
}

export function useSubmitPhoneVerifyMutation(navigate: (path: string) => void) {
	const {
		mutateAsync,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['submitPhoneVerifyCode'],
		mutationFn: (data: {
			phone: string;
			isPhoneVerified: boolean;
			wState: string;
		}) => fetchSubmitPhoneVerifyCode(data),
		onSuccess: async () => {
			navigate(ROUTES.REGISTRATION.PHONE_CODE);
		}
	});
	return {
		mutateAsync,
		mutationError,
		isError,
		isPending
	};
}

export function useAskNewPhoneCodeMutation() {
	const {
		mutateAsync: askNewCode,
		isError,
		error: askNewCodeError
	} = useMutation({
		mutationKey: ['askNewPhoneCode'],
		mutationFn: fetchAskNewPhoneCode
	});
	return {
		askNewCode,
		askNewCodeError,
		isError
	};
}

export function useSubmitPhoneCodeMutation(navigate: (path: string) => void) {
	const {
		isPending,
		mutateAsync,
		isError,
		error: mutationError
	} = useMutation({
		mutationKey: ['submitPhoneCode'],
		mutationFn: (data: { code: string }) => fetchSubmitPhoneCode(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			sessionStorage.setItem('isRegistrationPageInited', 'false');
			navigate(mapRegistrationPage(data.nextPageId) as string);
			return;
		}
	});
	return {
		mutateAsync,
		mutationError,
		isError,
		isPending
	};
}

export function useSubmitLastStepMutation(returnHost: string) {
	const {
		mutateAsync,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['submitPhoneCode'],
		mutationFn: (data: {
			name: string;
			password: string;
			referal: string;
			wState: string;
		}) => fetchSubmitLastStep(data),
		onSuccess: async () => {
			sessionStorage.clear();
			if (window.top) {
				window.top.location.href = returnHost;
			}
			localStorage.clear();
		}
	});
	return {
		mutateAsync,
		mutationError,
		isError,
		isPending
	};
}