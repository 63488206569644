import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useEffect, useRef, useState } from 'react';
import { CopyIcon } from '../../common/icons';
import { CustomButton } from '../../common/buttons';
import { ROUTES } from '../../../types/routes';
import QRCode from 'qrcode';

const SecretKeyLayout = () => {
	const { t } = useTranslation();
	const buttonRef = useRef();
	const navigate = useCustomNavigate();

	const [secretKey, setSecretKey] = useState<string>('');
	const [qrCodeImage, setQrCodeImage] = useState<string>('');
	const [isMobile, setIsMobile] = useState<boolean>(false);

	const initData = async () => {
		setSecretKey(sessionStorage.getItem('secretKey') as string);
		QRCode?.toDataURL(
			sessionStorage.getItem('qrcode') as string,
			{
				width: 250
			},
			(err, url) => {
				setQrCodeImage(url);
			}
		);
	}

	useEffect(() => {
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/iPhone/i)
		) {
			setIsMobile(true);
		}
		initData();
	}, []);

	const handleBackClick = () => {
		navigate(ROUTES.LOGIN.SETUP_TWOFA);
	};

	const handleCopyClick = async () => {
		await navigator.clipboard.writeText(secretKey);
	};

	const handleSubmit = async () => {
		navigate(ROUTES.LOGIN.CONFIRM_TWOFA);
	};

	return (
		<>
			<div className={'component'}>
				<div className={'innerComponent'}>
					<CustomButton
						isBack={true}
						onClick={handleBackClick}
						className={'backButton'}
					/>
					<h1 className={'confirmEmailText'}>
						{t('mfCodeSecretKey')}
					</h1>
					<h1 className={'confirmCodeText'}>
						{isMobile
							? t('mfCodeSecretKeyDescription')
							: t('mfCodeSecretKeyQR')}
					</h1>
					{isMobile ? (
						<div className={'setupMfCode-item-secretKey'}>
							<div className={'setupMfCode-item-secretKey-inner'}>
								<h1 className={'key'}>{t('mfCodeKey')}</h1>
								<div className={'key-text-inner'}>
									<h1 className={'key-text'}>{secretKey}</h1>
									<CopyIcon
										onClick={handleCopyClick}
										className={'bankDepositCopyIcon'}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className={'qrCode-image'}>
							<img src={qrCodeImage} alt={qrCodeImage} />
						</div>
					)}
					<CustomButton
						text={t('mfCodeNext') as string}
						type={'submit'}
						onClick={handleSubmit}
						className={'loginButtonError'}
						buttonRef={buttonRef}
					/>
				</div>
			</div>
		</>
	);
};

export default SecretKeyLayout;
