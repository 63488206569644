import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../common/buttons';
import { CustomCheckbox } from '../../common/checkboxes';
import { Whale } from '../../common/icons';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { TAnswers, TQuestionary } from '../../../types/types';
import { useSubmitQuestionaryMutation } from '../../../data/mutations/verification';
import { ThemeContext } from '../../../data/theme.provider';

const QuestionaryLayout = () => {
	const { theme } = useContext(ThemeContext);
	const [currentQuestion, setCurrentQuestion] = useState<number>(0);
	const [isTestStarted, setIsTestStarted] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState<boolean[]>([false, false, false]);

	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	const isButtonDisabled = isChecked.filter((check) => check).length === 0;

	const [questionary, setQuestionary] = useState<TQuestionary>(
		JSON.parse(sessionStorage.getItem('questionary') as string)
	);

	const [answers, setAnswers] = useState<TAnswers>([]);

	const { isError, isPending, mutateAsync } = useSubmitQuestionaryMutation(navigate);

	const [fakeError, setIsFakeError] = useState<boolean>(false);

	useEffect(() => {
		if (isError) {
			setIsFakeError(isError);
		}
	}, [isError]);

	useEffect(() => {
		setQuestionary(JSON.parse(sessionStorage.getItem('questionary') as string));
	}, []);

	const handleCurrentQuestionChange = () => {
		setIsChecked([false, false, false]);
		setCurrentQuestion(currentQuestion + 1);
	};

	const handleLastQuestionClick = () => {
		mutateAsync({
			wstate: new URLSearchParams(window.location.search).get('wstate') as string,
			questions: answers,
		});
	};

	const handleStartTestButtonClick = () => {
		setIsFakeError(false);
		setIsChecked([false, false, false]);
		setCurrentQuestion(0);
		setIsTestStarted(true);
	};

	const handleCheckBoxChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		idx: number
	) => {
		const localChecked = [false, false, false];
		localChecked[idx] = e.target.checked;
		setIsChecked(localChecked);

		const localAnswers = [...answers];
		if (
			localAnswers.find(
				(answer) =>
					answer.questionId ===
					questionary?.questions[currentQuestion].questionId
			)
		) {
			localAnswers[currentQuestion].selectedAnswers[0] =
				questionary?.questions[currentQuestion].answers[idx].answerId;
		} else {
			localAnswers.push({
				questionId: questionary?.questions[currentQuestion].questionId,
				selectedAnswers: [
					questionary?.questions[currentQuestion].answers[idx].answerId,
				],
			});
		}
		setAnswers(localAnswers);
	};

	if (fakeError) {
		return (
			<div className={'component'}>
				<div className={'innerComponent'}>
					<Whale className={'verificationWhale'} />
					<h1 className={'verificationQuestion'}>
						{t('verificationTestFailed')}
					</h1>
					<h1 className={'verificationTestDescription'}>
						{t('verificationTestFailedDescription')}
					</h1>
					<CustomButton
						text={t('verificationTestFailedButton') as string}
						type={'submit'}
						onClick={handleStartTestButtonClick}
						className={'verificationStartTestButton'}
					/>
				</div>
			</div>
		);
	}

	if (isTestStarted) {
		return (
			<div className={'component'}>
				<div className={'innerComponent'}>
					<h1 className={'verificationQuestionNumber'}>
						{t('verificationQuestionNumber').replace(
							'{number}',
							String(currentQuestion + 1)
						)}
					</h1>
					<h1 className={'questionText'}>
						{questionary.questions[currentQuestion].questionValue
							.split('. ')[1]
							.replace(':', '?')}
					</h1>
					<div className={'answersDiv'}>
						{questionary.questions[currentQuestion].answers.map(
							(answer, idx) => (
								<div className={'answerDiv'} key={answer.answerId}>
									<CustomCheckbox
										id={idx.toString()}
										isDarkMode={theme}
										className={'answerCheckBox'}
										checked={isChecked[idx]}
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => handleCheckBoxChange(e, idx)}
									/>
									<h1 className={'answerText'}>{answer.answerValue}</h1>
								</div>
							)
						)}
					</div>
					{currentQuestion + 1 === questionary.questions.length ? (
						<CustomButton
							isLoading={isPending}
							isDisabled={isButtonDisabled}
							text={t('registrationContinue') as string}
							type={'submit'}
							onClick={handleLastQuestionClick}
							className={'verificationStartTestButton'}
						/>
					) : (
						<CustomButton
							isLoading={isPending}
							isDisabled={isButtonDisabled}
							text={t('registrationContinue') as string}
							type={'submit'}
							onClick={handleCurrentQuestionChange}
							className={'verificationStartTestButton'}
						/>
					)}
				</div>
			</div>
		);
	}

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<h1 className={'verificationQuestionNumber'}>
					{t('verificationTestStartLogo')}
				</h1>
				<h1 className={'verificationTestDescription'}>
					{t('verificationTestDescription')}
				</h1>
				<CustomButton
					text={t('verificationStartTestButton') as string}
					type={'submit'}
					onClick={handleStartTestButtonClick}
					className={'verificationStartTestButton'}
				/>
			</div>
		</div>
	);
};

export default QuestionaryLayout;
