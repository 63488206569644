import { useContext, useEffect, useState } from 'react';
import { usePinInput } from 'react-pin-input-hook';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { PinInput } from '../../common/inputs';
import { CustomButton } from '../../common/buttons';
import { useConfirmTwoFaMutation } from '../../../data/mutations/login';
import { ParamContext } from '../../../data/param.provider';

const ConfirmTwoFaLayout = () => {
	const { wState } = useContext(ParamContext);

	const [fakeError, setFakeError] = useState<boolean>(false);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				mutateAsync({
					wstate: wState,
					code: values,
					key: sessionStorage.getItem('secretKey') as string,
					identityTypeName: 'GoogleAuthenticator',
					navigate: navigate
				});
			}
		},
		type: 'numeric',
		placeholder: ''
	});

	const navigate = useCustomNavigate();

	const { isError, error, mutateAsync, isPending } =
		useConfirmTwoFaMutation(navigate);

	const { t } = useTranslation();

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.LOGIN.GET_KEY);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<CustomButton
					isBack={true}
					onClick={handleBackClickButton}
					className={'backButton'}
				/>
				<h1 className={'confirmEmailText'}>
					{t('authenticationText')}
				</h1>
				<h1 className={'confirmCodeText'}>{t('mfCodeDescription')}</h1>
				<div className={'codeInputBlock'}>
					<PinInput
						isLoading={isPending}
						isError={fakeError}
						fields={fields}
					/>
					{fakeError ? (
						<h1 className={'errorText'}>
							{
								(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
							}
						</h1>
					) : (
						<div className={'errorText'} />
					)}
				</div>
			</div>
		</div>
	);
};

export default ConfirmTwoFaLayout;
