import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { CustomButton } from '../../common/buttons/index';
import { CustomInput } from '../../common/inputs';
import {
	useSubmitPhoneVerifyMutation,
	useSubmitRegistrationAgainMutation
} from '../../../data/mutations/registration';
import { ParamContext } from '../../../data/param.provider';
import { ConfirmRegistrationModal } from '../../common/modals';

const RegistrationPhoneLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const { wState } = useContext(ParamContext);

	const [phone, setPhone] = useState<string>('');
	const [isPhoneRequired, setIsPhoneRequired] = useState<boolean>(false);

	const [phoneErrorFake, setPhoneErrorFake] = useState<boolean>(false);

	const { mutateAsync, mutationError, isError, isPending } =
		useSubmitPhoneVerifyMutation(navigate);

	const [isConfirmModalOpen, setIsConfirmModalOpen] =
		useState<boolean>(false);

	const { mutateClearAsync, isClearLoading } =
		useSubmitRegistrationAgainMutation(navigate);

	useEffect(() => {
		const wStateInfo = JSON.parse(
			sessionStorage.getItem('wStateInfo') as string
		);
		const sessionPhone = sessionStorage.getItem('phone');
		if (wStateInfo) {
			setPhone(wStateInfo.initMobile?.replace('+', ''));
			setIsPhoneRequired(wStateInfo.initMobile?.length > 0);
		}
		if (sessionPhone && sessionPhone.length > 0) {
			setPhone(sessionPhone.replace('+', '').replaceAll(' ', ''));
			setIsPhoneRequired(true);
		}
	}, []);

	useEffect(() => {
		if (isError) {
			setPhoneErrorFake(isError);
		}
	}, [isError]);

	const handleIsConfirmModalOpen = () => {
		setIsConfirmModalOpen(!isConfirmModalOpen);
	};

	const handleRegistrationClick = async () => {
		sessionStorage.clear();
		await mutateClearAsync();
		setIsConfirmModalOpen(false);
		navigate(ROUTES.REGISTRATION.INDEX);
	};

	const buttonRef = useRef(null);

	const handlePhoneChange = (
		e: string | React.ChangeEvent<HTMLInputElement>
	) => {
		if (typeof e === 'string') {
			setPhoneErrorFake(false);
			setPhone(e as string);
			setIsPhoneRequired(String(e)?.length > 0);
		} else {
			setPhoneErrorFake(false);
			setPhone(e.target.value);
			setIsPhoneRequired(e.target.value?.length > 0);
		}
	};

	const handlePhoneClear = () => {
		setPhone('');
		setIsPhoneRequired(false);
	};

	const handleBackClick = () => {
		navigate(ROUTES.REGISTRATION.INDEX);
	};

	const handleSubmit = () => {
		sessionStorage.setItem('phone', phone);
		mutateAsync({
			phone: '+' + phone.replaceAll(' ', ''),
			isPhoneVerified: false,
			wState: wState
		});
		sessionStorage.setItem('phone', '+' + phone.replaceAll(' ', ''));
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<ConfirmRegistrationModal
					isLoading={isClearLoading}
					isModalOpen={isConfirmModalOpen}
					handleIsModalOpen={handleIsConfirmModalOpen}
					handleConfirm={handleRegistrationClick}
					text={t('startRegistrationAgainMain') as string}
				/>
				<CustomButton
					isBack={true}
					onClick={handleBackClick}
					className={'backButton'}
				/>
				<h1 className={'confirmEmailText'}>
					{t('registrationMobilePhone')}
				</h1>
				<h1 className={'confirmCodeText'}>
					{t('registrationProvideMobile')}
				</h1>
				<div className={'mobileInputBlock'}>
					<CustomInput
						isError={phoneErrorFake}
						isDisabled={false}
						isRequired={isPhoneRequired}
						type={'text'}
						id={'phone'}
						label={t('registrationPhone')}
						value={phone}
						onChange={handlePhoneChange}
						className={'phoneInput'}
						onClearClick={handlePhoneClear}
						placeHolder={'000000000000'}
						isMobile={true}
						inputPropsRef={buttonRef}
					/>
				</div>
				{phoneErrorFake ? (
					<h1 className={'registrationEmailError'}>
						{
							(mutationError as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
						}
					</h1>
				) : (
					<div className={'registrationEmailError'} />
				)}
				<CustomButton
					buttonRef={buttonRef}
					text={t('registrationStart') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'startButton'}
					isDisabled={phone?.length < 6}
					isLoading={isPending}
				/>
				<CustomButton
					isText={true}
					text={t('startRegistrationAgain') as string}
					onClick={handleIsConfirmModalOpen}
					className={'startButton'}
				/>
			</div>
		</div>
	);
};

export default RegistrationPhoneLayout;
