import { useTranslation } from 'react-i18next';
import { WhaleIcon } from '../../common/icons';
import { CircleProgress } from '../../common/loaders';
import { useWaitMutation } from '../../../data/mutations/verification';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useEffect } from 'react';
import { ROUTES } from '../../../types/routes';

const WaitLayout = () => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	
	const {isError} = useWaitMutation();

	useEffect(() => {
		if(isError) {
			navigate(ROUTES.REGISTRATION.MAINTAIN);
		}
	},[isError])

	return (
		<>
			<div className={'component'}>
				<div className={'innerComponent'}>
					<WhaleIcon className={''} />
					<h1 className={'maintainHeaderText'}>{t('sumsubWaitMain')}</h1>
					<h1 className={'maintainHeaderBody'}>{t('sumsubWaitBottom')}</h1>
                    <CircleProgress className={'circleWait'} size={30} thickness={6} />
				</div>
			</div>
		</>
	);
};

export default WaitLayout;
