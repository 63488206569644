import { useContext } from 'react';
import { LocaleContext } from '../data/locale.provider';

export const useCustomNavigate = () => {
	const { locale } = useContext(LocaleContext);

	return function (path: string) {
		window.location.replace(path.replace(':locale', locale) + window.location.search);
	};
};
