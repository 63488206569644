import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { CustomInput } from '../../common/inputs';
import { ParamContext } from '../../../data/param.provider';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../common/buttons';
import { ROUTES } from '../../../types/routes';
import { useSendCodePasswordRecovery } from '../../../data/mutations/login';

const PasswordRecoveryLayout = () => {
	const { wState } = useContext(ParamContext);

	const [email, setEmail] = useState<string>('');
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);

	const buttonRef = useRef(null);
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const [fakeError, setFakeError] = useState<boolean>(false);

	const { isError, isPending, error, sendCode } =
		useSendCodePasswordRecovery(navigate);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
		}
	}, [isError]);

	useEffect(() => {
		const wStateInfo = JSON.parse(
			sessionStorage.getItem('wStateInfo') as string
		);
		if (wStateInfo) {
			setEmail(wStateInfo.initEmail);
			setIsEmailRequired(wStateInfo.initEmail?.length > 0);
		}
	}, []);

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setEmail(e.target.value);
		setFakeError(false);
		e.target.value.length > 0
			? setIsEmailRequired(true)
			: setIsEmailRequired(false);
	};

	const handleEmailClearClick = () => {
		setEmail('');
		setFakeError(false);
		setIsEmailRequired(false);
	};

	const handlePasswordRecovery = () => {
		sessionStorage.setItem('email', email);
		sendCode({
			wState: wState,
			email: email,
			navigate
		});
	};

	return (
		<>
			<div>
				<div className={'component'}>
					<div className={'innerComponent'}>
						<CustomButton
							isBack={true}
							onClick={() => navigate(ROUTES.LOGIN.INDEX)}
							className={'backButton'}
						/>
						<h1 className={'welcomeText'}>{t('forgotText')}</h1>
						<h1 className={'confirmCodeText'}>
							{t('recoveryIndexBottom')}
						</h1>
						<CustomInput
							isTabDisable={true}
							isError={fakeError}
							isRequired={isEmailRequired}
							type={'email'}
							id={'email'}
							label={'Email'}
							value={email}
							onChange={handleEmailChange}
							className={'emailRecoveryInput'}
							onClearClick={handleEmailClearClick}
							inputPropsRef={buttonRef}
						/>
						{fakeError ? (
							<h1 className={'loginErrorText'}>
								{
									(error as any)?.message //eslint-disable-line @typescript-eslint/no-explicit-any
								}
							</h1>
						) : (
							''
						)}
						<CustomButton
							buttonRef={buttonRef}
							isLoading={isPending}
							text={t('registrationStart') as string}
							type={'submit'}
							onClick={handlePasswordRecovery}
							className={
								fakeError ? 'loginButtonError' : 'loginButton'
							}
							isDisabled={
								!new RegExp(
									/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
								).test(email)
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PasswordRecoveryLayout;
