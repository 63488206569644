import { Helmet } from 'react-helmet-async';
import { TITLES } from '../types/titles';
import VerificationLayout from '../components/verification/verification';

const Verification = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.VERIFICATION.INDEX}</title>
			</Helmet>
			<VerificationLayout />
		</>
	);
};

export default Verification;
