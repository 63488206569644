import { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "../../../data/locale.provider";
import { CustomButton } from "../../common/buttons";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { ROUTES } from "../../../types/routes";

const SetupTwoFaLayout = () => {
	const { locale } = useContext(LocaleContext);
	const { t } = useTranslation();
	const buttonRef = useRef();
	const navigate = useCustomNavigate();

	const handleSubmit = () => {
		navigate(ROUTES.LOGIN.GET_KEY);
	};

	const handleBackClick = () => {
		navigate(ROUTES.LOGIN.INDEX);
	};

	return (
		<div className={"component"}>
			<div className={"innerComponent"}>
				<CustomButton
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
				<h1 className={"confirmEmailText"}>{t("mfCodeDownloadAppText")}</h1>
				<div className={"forgotPasswordContainerText"}>
					{locale === "ru" ? (
						<h1 className={"setupMfCode-item-description-text"}>
							Загрузите и установите приложение «Google Authenticator» в
							<a
								rel="noreferrer noopener"
								target="_blank"
								href={
									"https://apps.apple.com/ru/app/google-authenticator/id388497605"
								}
							>
								{" "}
								App Store
							</a>
							,{" "}
							<a
								rel="noreferrer noopener"
								target="_blank"
								href={
									"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1"
								}
							>
								Google Play Store{" "}
							</a>
							или в магазине приложений другой платформы.
						</h1>
					) : (
						<h1 className={"setupMfCode-item-description-text"}>
							Download and install the Google Authenticator app in
							<a
								rel="noreferrer noopener"
								target="_blank"
								href={
									"https://apps.apple.com/ru/app/google-authenticator/id388497605"
								}
							>
								{" "}
								App Store
							</a>
							,{" "}
							<a
								rel="noreferrer noopener"
								target="_blank"
								href={
									"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1"
								}
							>
								Google Play Store{" "}
							</a>
							or in the app store of another platform.
						</h1>
					)}
				</div>
				<CustomButton
					text={t("mfCodeNext") as string}
					type={"submit"}
					onClick={handleSubmit}
					className={"loginButtonError"}
					buttonRef={buttonRef}
				/>
			</div>
		</div>
	);
};

export default SetupTwoFaLayout;
