import { API_ROUTES } from '../types/api.routes';
import { ROUTES } from '../types/routes';
import {
	TConfirmTwoFaRequestBody,
	TIdentity,
	TImpersonateBody,
	TImpersonateEulaBody,
	TLoginBody,
	TRecoveryRequestBody,
	TVerifyNewPasswordRequestBody
} from '../types/types';
import { customFetch } from '../utils';
import { v4 as uuidv4 } from 'uuid';

export async function 	fetchLoginOTP(
	wState: string,
	identityType: string,
	f2xUserAgent: string,
	navigate: (path: string) => void
) {
	const otpData = await customFetch(API_ROUTES.LOGIN.OTP.URL, {
		method: API_ROUTES.LOGIN.OTP.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			f2x_user_agent: f2xUserAgent,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: wState,
			identityType: identityType
		})
	});

	const jsonOtpData = await otpData.json();
	if (otpData.status != 200) {
		if (otpData.status === 503) {
			navigate(ROUTES.REGISTRATION.MAINTAIN);
		}
		throw jsonOtpData;
	}

	sessionStorage.setItem('loginIdentityType', identityType);
	sessionStorage.setItem('inputLength', jsonOtpData.length);

	return jsonOtpData;
}

export async function fetchLoginWithoutTwoFA(
	data: TLoginBody,
	navigate: (path: string) => void
) {
	const loginData = await customFetch(API_ROUTES.LOGIN.LOGIN.URL, {
		method: API_ROUTES.LOGIN.LOGIN.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			f2x_user_agent: data.f2xUserAgent,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: data.wState,
			login: data.email,
			password: data.password
		})
	});

	const jsonLoginData = await loginData.json();
	if (loginData.status != 200) {
		if (loginData.status === 503) {
			navigate(ROUTES.REGISTRATION.MAINTAIN);
		}
		if (loginData.status === 401) {
			if (jsonLoginData.code === 'MULTI_FACTOR_REQUIRED') {
				if (
					jsonLoginData.data.identityTypes.length === 1 &&
					jsonLoginData.data.identityTypes.find(
						(identity: TIdentity) =>
							identity.name === 'GoogleAuthenticator'
					)
				) {
					navigate(ROUTES.LOGIN.TWO_FA);
					throw jsonLoginData;
				}
				if (
					jsonLoginData.data.identityTypes.length === 1 &&
					jsonLoginData.data.identityTypes.find(
						(identity: TIdentity) => identity.name === 'Email'
					)
				) {
					sessionStorage.setItem('loginIdentityType', 'email');
					await fetchLoginOTP(
						data.wState,
						'email',
						data.f2xUserAgent,
						navigate
					);
					navigate(ROUTES.LOGIN.OTP);
					return jsonLoginData;
				}
				if (jsonLoginData.data.identityTypes.length > 1) {
					sessionStorage.setItem(
						'identityTypes',
						JSON.stringify(
							jsonLoginData.data.identityTypes.map(
								//eslint-disable-next-line
								(identityType: any) =>
									(identityType = {
										key: identityType.name,
										value: identityType.name
									})
							)
						)
					);
					navigate(ROUTES.LOGIN.SELECT_TWOFA);
					throw jsonLoginData;
				}
			}
			throw jsonLoginData;
		}
		throw jsonLoginData;
	}

	return jsonLoginData;
}

export async function fetchLoginWithTwoFA(
	data: TLoginBody,
	navigate: (path: string) => void
) {
	const loginData = await customFetch(API_ROUTES.LOGIN.LOGIN.URL, {
		method: API_ROUTES.LOGIN.LOGIN.METHOD,
		headers: {
			f2x_request_id: uuidv4(),
			f2x_user_agent: data.f2xUserAgent,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			wstate: data.wState,
			login: data.email,
			password: data.password,
			twoFA: data.twofa
		})
	});

	const jsonLoginData = await loginData.json();
	if (loginData.status != 200) {
		if (loginData.status === 503) {
			navigate(ROUTES.REGISTRATION.MAINTAIN);
		}
		throw jsonLoginData;
	}

	return jsonLoginData;
}

export async function fetchImpersonate(
	data: TImpersonateBody,
	navigate: (path: string) => void
) {
	const impersonateData = await customFetch(
		API_ROUTES.LOGIN.IMPERSONATE.URL,
		{
			method: API_ROUTES.LOGIN.IMPERSONATE.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				f2x_user_agent: data.f2xUserAgent,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				wstate: data.wstate,
				checkBoxesResult: data.checkBoxesResult
			})
		}
	);

	const jsonImpersonateData = await impersonateData.json();
	if (impersonateData.status != 200) {
		if (impersonateData.status === 503) {
			navigate(ROUTES.REGISTRATION.MAINTAIN);
		}
		throw jsonImpersonateData;
	}

	return jsonImpersonateData;
}

export async function fetchImpersonateEula(data: TImpersonateEulaBody) {
	const impersonateData = await customFetch(
		API_ROUTES.LOGIN.EULA_IMPERSONATE.URL +
			'?' +
			new URLSearchParams({
				wstate: data.wstate
			}),
		{
			method: API_ROUTES.LOGIN.EULA_IMPERSONATE.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				f2x_user_agent: data.f2xUserAgent,
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonImpersonateData = await impersonateData.json();
	if (impersonateData.status != 200) {
		if (impersonateData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale) +
					window.location.search
			);
		}
		throw jsonImpersonateData;
	}

	return jsonImpersonateData;
}

export async function fetchRequestCodeForRecovery(data: TRecoveryRequestBody) {
	const recoveryRequestData = await customFetch(
		API_ROUTES.LOGIN.SEND_PASSWORD_RECOVERY_CODE.URL,
		{
			method: API_ROUTES.LOGIN.SEND_PASSWORD_RECOVERY_CODE.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				wstate: data.wState,
				email: data.email
			})
		}
	);

	if (recoveryRequestData.status != 200) {
		if (recoveryRequestData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale) +
					window.location.search
			);
		}
		throw recoveryRequestData;
	}

	return recoveryRequestData;
}

export async function fetchVerifyCodePassword(
	data: TVerifyNewPasswordRequestBody
) {
	const body = {
		wstate: data.wState,
		email: data.email
	};

	if (data.code) {
		Object.assign(body, { code: data.code });
	}
	if (data.newPassword) {
		Object.assign(body, { newPassword: data.newPassword });
	}

	const recoveryRequestData = await customFetch(
		API_ROUTES.LOGIN.CHANGE_PASSWORD.URL,
		{
			method: API_ROUTES.LOGIN.CHANGE_PASSWORD.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}
	);

	const jsonRecoveryRequestData = await recoveryRequestData.json();
	if (recoveryRequestData.status != 200) {
		if (
			recoveryRequestData.status === 400 &&
			jsonRecoveryRequestData.code === 'PASSWORD_REQUIRED'
		) {
			data.navigate(ROUTES.LOGIN.NEW_PASSWORD);
			throw jsonRecoveryRequestData;
		}
		if (recoveryRequestData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale) +
					window.location.search
			);
		}
		throw jsonRecoveryRequestData;
	}

	return jsonRecoveryRequestData;
}

export async function fetchLoginSecretKey() {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;

	const secretKeyData = await customFetch(
		API_ROUTES.LOGIN.SECRET_KEY.URL + "?" + new URLSearchParams({
			wstate: wState
		}),
		{
			method: API_ROUTES.LOGIN.SECRET_KEY.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			}
		}
	);

	const jsonSecretKeyData = await secretKeyData.json();
	if (secretKeyData.status != 200) {
		if (secretKeyData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale) +
					window.location.search
			);
		}
		throw jsonSecretKeyData;
	}

	sessionStorage.setItem('qrcode', jsonSecretKeyData.uri);
	sessionStorage.setItem('secretKey', jsonSecretKeyData.key);

	return jsonSecretKeyData;
}

export async function fetchConfirmTwoFa(data: TConfirmTwoFaRequestBody) {
	const body = {
		wstate: data.wstate,
		code: data.code,
		identityTypeName: data.identityTypeName,
		key: data.key,
	};

	const confirmTwoFaRequestData = await customFetch(
		API_ROUTES.LOGIN.ENABLE_TWOFA.URL,
		{
			method: API_ROUTES.LOGIN.ENABLE_TWOFA.METHOD,
			headers: {
				f2x_request_id: uuidv4(),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}
	);

	const jsonConfirmTwoFaRequestData = await confirmTwoFaRequestData.json();
	if (confirmTwoFaRequestData.status != 200) {
		if (confirmTwoFaRequestData.status === 503) {
			const locale =
				window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(
				ROUTES.REGISTRATION.MAINTAIN.replace(':locale', locale) +
					window.location.search
			);
		}
		throw jsonConfirmTwoFaRequestData;
	}

	return jsonConfirmTwoFaRequestData;
}
