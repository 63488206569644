import { useMutation, useQuery } from '@tanstack/react-query';
import { ROUTES } from '../../types/routes';
import { TAnswers, TSubmitVerificationBody } from '../../types/types';
import {
	fetchSubmitQuestionaryTest,
	fetchSumSubInfo,
	fetchVerificationSubmit
} from '../../api/verification';
import { fetchSubmitClearTest } from '../../api/registration';
import { mapRegistrationPage } from '../../utils';

export function useVerificationSubmitMutation(
	navigate: (path: string) => void
) {
	const { mutateAsync, isError, error, isPending } = useMutation({
		mutationKey: ['submitUserData'],
		mutationFn: (data: TSubmitVerificationBody) =>
			fetchVerificationSubmit(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			navigate(mapRegistrationPage(data.nextPageId) as string);
			return;
		}
	});
	return {
		mutateAsync,
		error,
		isError,
		isPending
	};
}

export function useSumsubClearMutation(navigate: (path: string) => void) {
	const { mutateAsync, isError, error, isPending } = useMutation({
		mutationKey: ['sumsubClear'],
		mutationFn: () => fetchSubmitClearTest(),
		onSuccess: async () => {
			navigate(ROUTES.REGISTRATION.INDEX);
		}
	});
	return {
		mutateAsync,
		error,
		isError,
		isPending
	};
}

export function useSubmitQuestionaryMutation(navigate: (path: string) => void) {
	const { mutateAsync, isError, error, isPending } = useMutation({
		mutationKey: ['submitQuestionary'],
		mutationFn: (data: { wstate: string; questions: TAnswers }) =>
			fetchSubmitQuestionaryTest(data),
		//eslint-disable-next-line
		onSuccess: async (data: any) => {
			navigate(mapRegistrationPage(data.nextPageId) as string);
			return;
		}
	});
	return {
		mutateAsync,
		error,
		isError,
		isPending
	};
}

export function useWaitMutation() {
	const { data, isPending, isError, error } = useQuery({
		queryKey: ['verificationWaitSumSub'],
		queryFn: () => fetchSumSubInfo(),
		staleTime: 3000,
		refetchInterval: 3000
	});
	return {
		data,
		isPending,
		isError,
		error
	};
}
