export const API_ROUTES = {
	REGISTRATION: {
		DICTIONARY_BY_ID: {
			METHOD: 'GET',
			URL: '/api/v1/user/registration/dictionaries/{dictId}'
		},
		REGISTRATION_START: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/start'
		},
		REGISTRATION_INFO: {
			METHOD: 'GET',
			URL: '/api/v1/widget/user/registration/info'
		},
		GET_EULA: {
			METHOD: 'GET',
			URL: '/api/v1/widget/user/registration/eula'
		},
		SEND_EULA: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/eula'
		},
		SEND_CODE: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/send'
		},
		VERIFY_CODE: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/verify'
		},
		VERIFY_IDENTITY: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/verify/identity'
		},
		CREATE_ACCOUNT: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/create'
		},
		CLEAR: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/clear'
		},
		SUMSUB_INFO: {
			METHOD: 'GET',
			URL: '/api/v1/widget/user/registration/sumsub'
		},
		PROFILE: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/create/profile'
		},
		GET_QUESTIONARY: {
			METHOD: 'GET',
			URL: '/api/v1/widget/user/registration/questionnaire'
		},
		SEND_QUESTIONARY: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/registration/questionnaire'
		}
	},
	COMMON: {
		WSTATE_INFO: {
			METHOD: 'GET',
			URL: '/api/v1/widget/user/{wstate}'
		}
	},
	PAYMENTS: {
		WSTATE_INFO: {
			METHOD: 'GET',
			URL: '/api/v1/widget/payments/{wstate}'
		},
		WITHDRAW: {
			METHOD: 'POST',
			URL: '/api/v1/widget/payments/verify'
		},
		REJECT: {
			METHOD: 'POST',
			URL: '/api/v1/widget/payments/reject'
		},
		SEND_CODE: {
			METHOD: 'POST',
			URL: '/api/v1/widget/payments/send'
		}
	},
	LOGIN: {
		LOGIN: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/login'
		},
		IMPERSONATE: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/eula'
		},
		EULA_IMPERSONATE: {
			METHOD: 'GET',
			URL: '/api/v1/widget/user/eula'
		},
		OTP: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/login/send'
		},
		SEND_PASSWORD_RECOVERY_CODE: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/password/recovery/request'
		},
		CHANGE_PASSWORD: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/password/recovery/change'
		},
		SECRET_KEY: {
			METHOD: 'GET',
			URL: '/api/v1/widget/user/security/twofa/googleauthenticator/newkey'
		},
		ENABLE_TWOFA: {
			METHOD: 'POST',
			URL: '/api/v1/widget/user/security/twofa/enable'
		}
	}
};
