import { Helmet } from 'react-helmet-async';
import { TITLES } from '../types/titles';
import WithdrawSummaryLayout from '../components/withdraw/summary';

const WithdrawSummary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.WITHDRAW.SUMMARY}</title>
			</Helmet>
			<WithdrawSummaryLayout />
		</>
	);
};

export default WithdrawSummary;
