import { ROUTES } from '../types/routes';

export async function customFetch(
	input: RequestInfo | URL,
	init?: RequestInit | undefined
): Promise<Response> {
	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
	const customHeaders = {
		'Accept-Language':
			locale.toLocaleLowerCase() + '-' + locale.toUpperCase(),
		f2x_user_agent: sessionStorage.getItem('f2xUserAgent'),
		...init?.headers
	};
	if (init?.headers) {
		//eslint-disable-next-line
		init.headers = customHeaders as any;
	}

	return await fetch(input, init);
}

export function mapLoginPage(page: string): string | undefined {
	const loginPages: {
		[key: string]: string;
	} = {
		loginTwoFaPageId: ROUTES.LOGIN.SETUP_TWOFA,
		loginSamsubPageId: ROUTES.VERIFICATION.SUMSUB,
		loginEulaImpersonatePageId: ROUTES.LOGIN.IMPERSONATE,
		finishPageId: ROUTES.REDIRECT.INDEX
	};
	if (loginPages[page]) {
		return loginPages[page];
	}
}
 
export function mapRegistrationPage(page: string): string | undefined {
	const registrationPages: {
		[key: string]: string;
	} = {
		registrationEulaEmailIdentityPageId: ROUTES.REGISTRATION.INDEX,
		registrationPhonePageId: ROUTES.REGISTRATION.PHONE,
		registrationProfilePageId: ROUTES.VERIFICATION.INDEX,
		registrationSamsubPageId: ROUTES.VERIFICATION.SUMSUB,
		blockchainTestPageId: ROUTES.VERIFICATION.QUESTIONARY,
		finishPageId: ROUTES.REDIRECT.INDEX
	};
	if (registrationPages[page]) {
		return registrationPages[page];
	}
}
