import { Helmet } from 'react-helmet-async';
import { TITLES } from "../types/titles";
import RegistrationEmailCodeLayout from "../components/registration/email-code";

const RegistrationEmailCode = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.EMAIL}</title>
			</Helmet>
			<RegistrationEmailCodeLayout />
		</>
	);
};

export default RegistrationEmailCode;
