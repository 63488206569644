import React, { createContext } from 'react';
import { TTheme } from '../types/types';

export const ThemeContext = createContext({
	theme: 'light',
} as {
	theme: TTheme;
});

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
	const theme = localStorage.getItem('theme') as TTheme;

	return <ThemeContext.Provider value={{ theme }}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
