import { Helmet } from 'react-helmet-async';
import { TITLES } from '../types/titles';
import QuestionaryLayout from '../components/verification/questionary/questionary';
import { QueryClient } from '@tanstack/react-query';
import { fetchQuestionary } from '../api/verification';

export const verificationQuestionaryLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['verificationSumSub'],
		queryFn: () => fetchQuestionary(),
		staleTime: Infinity
	});
};

const Questionary = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.VERIFICATION.QUESTIONARY}</title>
			</Helmet>
			<QuestionaryLayout />
		</>
	);
};

export default Questionary;
