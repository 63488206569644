import { Helmet } from 'react-helmet-async';
import { TITLES } from '../types/titles';
import RegistrationPersonalLayout from '../components/registration/personal';
import { QueryClient } from '@tanstack/react-query';
import { fetchRegistrationEula } from '../api/registration';
import { useEffect } from 'react';

export const registrationPersonalLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['registrationEula'],
		queryFn: () => fetchRegistrationEula(),
		staleTime: Infinity
	});
};

const RegistrationPersonal = () => {
	useEffect(() => {
		sessionStorage.setItem('isRegistrationPageInited', 'true');
	}, []);

	return (
		<>
			<Helmet>
				<title>{TITLES.REGISTRATION.PERSONAL}</title>
			</Helmet>
			<RegistrationPersonalLayout />
		</>
	);
};

export default RegistrationPersonal;