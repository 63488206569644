import { Helmet } from 'react-helmet-async';
import { TITLES } from '../types/titles';
import LoginLayout from '../components/login';
import { QueryClient } from '@tanstack/react-query';
import { useLoaderData } from 'react-router-dom';
import { TWStateInfo } from '../types/types';
import { fetchWStateInfoRegistration } from '../api/common';

export const loginLoader = () => {
	const wState = new URLSearchParams(window.location.search).get(
		'wstate'
	) as string;
	return new QueryClient().fetchQuery({
		queryKey: ['loginLoader'],
		queryFn: () => fetchWStateInfoRegistration(wState),
		staleTime: Infinity
	});
};

const Login = () => {
	const data = useLoaderData();

	return (
		<>
			<Helmet>
				<title>{TITLES.LOGIN.INDEX}</title>
			</Helmet>
			<LoginLayout data={data as TWStateInfo} />
		</>
	);
};

export default Login;
