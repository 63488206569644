export const ROUTES = {
	REGISTRATION: {
		INDEX: '/:locale/widget/registration',
		EMAIL_CODE: '/:locale/widget/registration/code',
		PHONE: '/:locale/widget/registration/phone',
		PHONE_CODE: '/:locale/widget/registration/phone/code',
		SUMMARY: '/:locale/widget/registration/summary',
		MAINTAIN: '/:locale/widget/registration/maintain'
	},
	LOGIN: {
		INDEX: '/:locale/widget/login',
		TWO_FA: '/:locale/widget/login/2fa',
		OTP: '/:locale/widget/login/otp',
		GET_KEY: '/:locale/widget/login/key',
		SETUP_TWOFA: '/:locale/widget/login/setupTwoFa',
		CONFIRM_TWOFA: '/:locale/widget/login/confirmTwoFa',
		SELECT_TWOFA: '/:locale/widget/login/selectTwoFa',
		IMPERSONATE: '/:locale/widget/login/impersonate',
		PASSWORD_RECOVERY: '/:locale/widget/login/recovery',
		CONFIRM_EMAIL: '/:locale/widget/login/recovery/confirm',
		NEW_PASSWORD: '/:locale/widget/login/recovery/password'
	},
	VERIFICATION: {
		INDEX: '/:locale/widget/verification',
		QUESTIONARY: '/:locale/widget/verification/questionary',
		SUMSUB: '/:locale/widget/verification/sumsub',
		WAIT: '/:locale/widget/verification/wait',
	},
	WITHDRAW: {
		INDEX: '/:locale/widget/payments',
		TWOFA: '/:locale/widget/payments/2fa',
		SUMMARY: '/:locale/widget/payments/finish'
	},
	REDIRECT: {
		INDEX: '/:locale/widget/finish'
	},
	LOADING: {
		INDEX: '/:locale/widget'
	},
	COMMON: {
		DOCUMENT: '/:locale/document'
	}
};
