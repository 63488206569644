import SumsubWebSdk from '@sumsub/websdk-react';
import { useContext, useEffect, useState } from 'react';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { LocaleContext } from '../../../data/locale.provider';

const VerificationSumSubLayout = () => {
	const { locale } = useContext(LocaleContext);
	const [sumSubAccessToken, setSumSubAccessToken] = useState<string>('');

	const navigate = useCustomNavigate();

	const [isDesktop, setIsDesktop] = useState<boolean>(false);

	useEffect(() => {
		const sumsubInfo = JSON.parse(
			sessionStorage.getItem('sumsubInfo') as string
		);
		if (sumsubInfo) {
			setSumSubAccessToken(sumsubInfo?.widgetConfiguration?.accessToken);
		}
		if (window.innerWidth >= 481) {
			setIsDesktop(true);
		}
	}, []);

	const expirationHandler = () => {
		console.log('');
	};

	//eslint-disable-next-line
	const sumSubMessageHandler = (type: string, payload: any) => {
		console.log('messageType', type);
		console.log('payload', payload);
		if (
			type === 'idCheck.onApplicantStatusChanged' &&
			payload?.reviewResult &&
			payload?.reviewResult?.reviewAnswer === 'GREEN'
		) {
			navigate(ROUTES.VERIFICATION.WAIT);
		}
	};

	if (sumSubAccessToken === '') {
		return <></>;
	}

	return (
		<div
			className={'component'}
			style={
				isDesktop
					? {
							bottom: 'auto',
							marginTop: '64px'
					  }
					: {}
			}
		>
			<SumsubWebSdk
				config={{
					lang: locale
				}}
				accessToken={sumSubAccessToken}
				onMessage={sumSubMessageHandler}
				expirationHandler={expirationHandler}
			/>
		</div>
	);
};

export default VerificationSumSubLayout;
