import React, { useContext, useEffect } from 'react';
import { CustomCheckbox } from '../../common/checkboxes';
import { TRegistrationCheckbox } from '../../../types/types';
import { ThemeContext } from '../../../data/theme.provider';
import { ROUTES } from '../../../types/routes';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';

interface Props {
	checked: boolean[];
	isError: boolean;
	setIsChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
	//eslint-disable-next-line
	checkBoxes: any;
}
const RegistrationCheckboxes = ({
	checked,
	setIsChecked,
	isError,
	checkBoxes,
}: Props) => {
	const { theme } = useContext(ThemeContext);

	const handleCheckBoxChange = (isChecked: boolean, idx: number) => {
		const localChecked = [...checked];
		localChecked[idx] = isChecked;
		setIsChecked(localChecked);
	};

	const navigate = useCustomNavigate();

	//eslint-disable-next-line
	function mapAnchorTag(event: any) {
		event.preventDefault();
		sessionStorage.setItem('href', event?.target?.href);
		navigate(ROUTES.COMMON.DOCUMENT);
	}

	useEffect(() => {
		const anchorTags = document.getElementsByTagName('a');
		for (const item of anchorTags) {
			item.onclick = mapAnchorTag;
		}
	}, []);

	return (
		<div className={'checkboxes'}>
			{checkBoxes.checkBoxes.map((checkBox: TRegistrationCheckbox, idx: number) => (
				<div className={idx === 0 ? 'firstCheckBox' : 'secondCheckBox'}>
					<CustomCheckbox
						id={idx.toString()}
						isDarkMode={theme}
						isError={isError}
						key={idx}
						checked={checked[idx]}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							handleCheckBoxChange(e.target.checked, idx)
						}
					/>
					<h1
						className={'checkBox-h1'}
						dangerouslySetInnerHTML={{
							__html: checkBox?.msgText
								.replaceAll('/n', '')
								.replaceAll(
									'href',
									"target='_blank' rel='noreferrer noopener' href"
								)
								.replaceAll('.html', '.html?isIframe=true'),
						}}
					></h1>
				</div>
			))}
		</div>
	);
};

export default RegistrationCheckboxes;
