import { useContext } from 'react';
import { UpdatedLogo } from '../icons';
import { ThemeContext } from '../../../data/theme.provider';
import { TTheme } from '../../../types/types';

const Header = ({ children }: { children: React.ReactNode }) => {
	const {theme} = useContext(ThemeContext);

	return (
		<>
			<div className='logoHeader'>
				<UpdatedLogo theme={theme as TTheme} />
			</div>
			{children}
		</>
	);
};

export default Header;
